@import "./cmd.css";

@import "./uxi.css";

:root {
    --brand-color: #276395;
    --hover-color: #99bddb;
    --body-color: #69686d;
    --btn-border-radius:20px;
}


.bg-white{
    background-color: transparent !important;
}

body{
    font-family: 'Montserrat', sans-serif;
}

.title{

  font-weight: bold;
}

h1{
    color: var(--brand-color);
}

.container:first-child > div > div {
    padding: 0 !important;
}

/*
.container form:first-of-type {
    padding: 0 !important;
    width: 1140px !important;
    margin: auto !important;
    background: white;
    padding: 52px 140px !important;
}

*/


.navbar{
	padding-bottom: 0;

    padding: 0;
    text-align: center;
    justify-content: center;
    display: block;
    background-color: var(--brand-color);
}

.navbar img{
	max-width: 232px;
}

@media only screen and (max-width: 516px){
    .navbar img {
        max-width: 182px;
    }
}




body>.container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
}


.main-content-a.flex-column.flex-sm-row.px-0.px-md-5.row {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.panel-a-white.col-12.px-md-5.py-5 {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
}

.justify-content-center-error {
    flex: 1 !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding-top: 0 !important;
    color: var(--body-color);
    justify-content: center!important;
}

.row.justify-content-center {
    margin: 0 !important;
    flex: 1 !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding-top: 0 !important;
}

.row.justify-content-center > div:first-child {
    min-width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.row.justify-content-center .col-12.col-md-10 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1;
    padding: 0 !important;
}

/*
.row.py-2.intro-title {
    width: 1140px !important;
    margin: auto;
}

.container:first-child .panel-a-white form, .container .intro-area + form + form, .container .intro-area + form{
    width: 1140px !important;
    margin: auto !important;
    background: white;
    padding: 52px 140px !important;
}
*/

form[action="https://autentika-cmd.gov.cv/login/cmd"]{
    /*background-color: red !important;*/
}

form[action="https://autentika-cmd.gov.cv/login/cmd"] div.form-group:first-of-type {
  float: left !important;
  width: 50% !important;
}

form[action="https://autentika-cmd.gov.cv/login/cmd"] div.form-group:nth-of-type(1),
form[action="https://autentika-cmd.gov.cv/login/cmd"] div.form-group:nth-of-type(2) {
    background-color: orange;
    padding: 0 0 12px;
    display: flex;
    flex-direction: column !important;
}


.container .intro-area + form + .row.flex-column.py-4{
    border-top: 1px dashed;
    /*width: 1140px !important;*/
    margin: auto !important;
    background: white;
    padding: 12px 140px 20px !important;
}


.main-content-a .panel-a-white .container, .main-content-a .panel-a-white .table{
    background-color: transparent;
}

.intro-area {
    background: white;
    margin-bottom: 15px;
}

.intro-area .intro-title .title {
border: none;
    font-size: 18px;
    margin: 28px 0;
    padding: 0;
    color: #555;
}





label {
    width: 100%;
}

textarea:focus, input:focus{
    outline: none;
}

input[type=tel], input[type=password]{
    box-shadow: 0 0 8px 1px #e4e4e4;
    border-radius: 6px !important;
    border: 1px solid transparent;
    transition:  all 300ms ease;
    height: 40px !important;
}

input[type=tel]:focus, input[type=password]:focus{
    border: 1px solid #e7f1ff;
    box-shadow: 0 0 13px 1px #d6d6d6 !important;
}


button.btn.btn-primary{
   border-radius: 20px;
    text-transform: capitalize !important;
    background-color: var(--brand-color)!important;
    border: 1px solid var(--brand-color) !important;
    padding: 8px 36px;
    font-size: 12px !important;
    transition: all 600ms ease;
}

button.btn-back.btn.btn-primary{
    border: 1px solid var(--brand-color) !important;
    background-color: transparent !important;
    color: var(--brand-color) !important;
    transition: all 600ms ease;
}

button.btn.btn-primary:hover{
     color: var(--brand-color) !important;
 background-color: var(--hover-color) !important;
    border: 1px solid var(--hover-color) !important;
}

.iti.iti--allow-dropdown.w-100 {
    max-width: 320px;
}





@media only screen and (max-width: 992px){
   .row.py-2.intro-title, .main-content-a .panel-a-white form{
    width: 100% !important;
}

.main-content-a .panel-a-white form{
    padding: 15px 10px !important;
}

.iti.iti--allow-dropdown.w-100 {
    width: 100% !important;
}

}
/*
.container .panel-a-white .container.px-0.px-md-5 > .row.justify-content-center:nth-child(2){
    padding: 0 !important;
    width: 1140px !important;
    max-width: 1140px !important;
    min-width: 0 !important;
    margin: auto !important;
    background: white;
    padding: 23px 140px 0!important;
}
*/
/*==============================*/

.container.login-mode{
  max-width: 100% !important;
}

.login-mode .title{
  font-family: 'Comfortaa', cursive;
  font-weight: bold;
}

.login-mode .navbar{
  padding-bottom: 0;
    height: 57px;
    padding: 0;
    text-align: center;
    justify-content: center;
    display: block;
    background-color: var(--brand-color);
}

.login-mode .navbar img{
  width: 160px;
    margin-top: 8px;
}

.login-mode .container.px-0{
    width: 100% !important;
    max-width: 100% !important;
}

.login-mode .row.justify-content-center.py-2.py-md-4.px-0 {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
}

.login-mode .col-12.col-md-10.px-0.py-2.p-md-4 {
   padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    flex: 1;
}

.login-mode .intro-area {
    background: white;
}



.login-mode form.col.p-0.p-md-2 {
    padding: 0px !important;
}

.login-mode .intro-boxes {
   /* max-width: 1140px;*/
    margin: auto;
}
.intro-boxes .intro-panel .title{
    color:var(--brand-color);
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #e7f1ff;
}

.intro-boxes .intro-panel p, .intro-boxes .intro-panel ul{
    font-weight: 500;
    margin-bottom: 20px;
}

.intro-boxes .intro-panel .full-button{
    width: auto;
    padding-right: 40px!important;
    background-image: url(../img/bigarrow.png);
}

.login-mode .col-12.col-lg-5.intro-panel .title {
    color: var(--brand-color);
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--brand-color);
    margin-bottom: 8px;
    font-weight: bold;
}


.login-mode .col-12.col-lg-5.intro-panel .btn {
    width: fit-content;
     width: -moz-fit-content;
    padding: 8px 36px;
    display: inline !important;
    align-self: auto !important;
    border-radius: 20px;
    background-color: var(--brand-color);
    border: 1px solid var(--brand-color);
    text-transform: capitalize;
    font-size: 18px;
    transition: all 600ms ease;
}

.login-mode .col-12.col-lg-5.intro-panel .btn:hover{
    color: var(--brand-color) !important;
    background-color: var(--hover-color) !important;
    border: 1px solid var(--hover-color);
}


.login-mode .intro-boxes .intro-panel {
    padding: 50px 40px !important;
}

.intro-boxes .intro-panel button{
    align-self:flex-start;
}


/*  willy */

a.disabled {
    pointer-events: none;
}