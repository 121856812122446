/* Autentika */
/*
1. Import Fonts
2. Navbar
3. Titles
4. Menu
5. Buttons
6. Tables
7. Panels
8. Footer
*/


body, wrapper {
    min-height:100vh;
}

main {
    flex:1;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

a {
    color: #0077cb;
}
caption {
    text-transform: uppercase;
}

/*************************************************** */
/* Areas */
/*************************************************** */
body {
    

    font-weight: 400;
    background-color: #f1f1f1;
    margin: 0 !important;
}
.justify-content-md-center, .main-content-a {
    background-color: #f1f1f2;
}
.main-content-a  {
    padding-left: 4rem;
    padding-right: 4rem;

}
.container-fluid {
    padding: 0;
}
.container {
    /*max-width: 100%;*/
}
/*************************************************** */
/* Navbar */
/*************************************************** */
.navbar {
    height:100px;
    background-color: #0077CB;
    color: #fff;
    padding-bottom: 1.5rem;

}
.navbar-brand {
    width:232px;
    height: 46px;

    margin-left: 15px;
    margin-right: 15px;
    align-self: flex-end;

}

.navbar-brand img {
    max-width: 100%;
    height: auto;
}
.navbar form {
    margin-bottom: 0;
    align-self: flex-end;
}

nav .btn {
    align-self: flex-end;
}

nav [type="submit"] {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;

    padding-left: 30px;
    padding-right: 30px;

    border: 2px solid white;
    color: white;
    background-color: #0077cb;
    line-height: 0;
}

nav [type="submit"]:hover {
    background-color: white;
    color: #0077cb;
}

/*************************************************** */
/* Titles */
/*************************************************** */
h1, h2 {
    text-transform: uppercase;
}
.h2-a {
    margin: 0;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    color: #0077CB;
    background-color: white;
    font-size:1.2em;
    font-weight: 500;

}
.h2-a span {
    margin-left: 15px;
}

h4 {
    text-transform: uppercase;
}

h5 {
    color: #0b79bb;
    font-size: 1.00rem;
}
/*************************************************** */
/* Menu */
/*************************************************** */
.main-content-a .table-of-contents {
    padding: 0 !important;
    border-radius: 0;
    border: 0;
}

.main-content-a .table-of-contents h4 {
    background-color: #0077CB;
    color: white;
    padding-top: 1.15em;
    padding-bottom: 1em;
    background-image: url(https://vortica.net/img/burguer2.png);
    padding-left:40px !important;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 0.9em;

}
.main-content-a .table-of-contents li:first-of-type {
    margin-top:4px !important;
    border-top: 0 !important;
    border-bottom: 0 !important;

}
.main-content-a .table-of-contents li {
    border-top: 0;
    border-bottom: 0;
    background-color: white;
    margin-top: 4px !important;
    color: #0077CB;
    font-family: 'Poppins', sans-serif;

    padding-top:7px;
    padding-bottom: 7px;
    cursor: pointer;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.main-content-a .table-of-contents li:hover {
    background-color: #cde3f2;
}
.main-content-a .table-of-contents li a {
    font-size:0.7rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;

}
.main-content-a .table-of-contents li a:hover, .main-content-a .table-of-contents li a:visited, .main-content-a .table-of-contents li a:link, .main-content-a .table-of-contents li a:active {
    text-decoration: none !important;
}
/*************************************************** */
/* Breadcrumb */
/*************************************************** */

nav[aria-label="breadcrumb"] {
    color: #0077CB;
    margin-top: 10px;



}
.breadcrumb {
    font-size: 0.7rem !important;
    padding: .75rem 0 0.25rem;
    background-color: inherit;
    font-weight: 500 !important;
    font-family: 'Poppins', sans-serif !important;
}
li.breadcrumb-item.active {
    color: #aaa;
}
/*************************************************** */
/* Buttons */
/*************************************************** */
[type="submit"], [type="button"] {

    /*
    background-color: #0077CB !important;
    color: white !important;

    border: 2px solid white !important;
    font-size: 0.8rem;
    */

    border-radius: 0;
    text-transform: uppercase;

    padding-left: 30px;
    padding-right: 30px;
}
.btn {
    border: 0;
    border-radius: 0;

    padding-left: 30px;
    padding-right: 30px;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    background-clip: padding-box;

}

.btn-primary, .btn-success {
    background-color: #0077cb !important;
}
.btn.btn-primary, .btn.btn-success, .btn.btn-danger, .btn.btn-white {

    font-size: 0.7rem !important;
    text-transform: uppercase;
    align-self: flex-end;
}

.btn.btn-secondary {
    background-color: #cde3f2 !important;
    border: 2px solid #0077CB !important;
    color: #0077CB !important;
    border-radius: 0;
    font-size: 0.7rem !important;
}

.btn.btn-secondary:hover {
    background-color: #0077CB !important;
    border: 2px solid #0077CB !important;
    color: white !important;
}

.btn-back {
    background-color: #707070 !important;
}
.btn.btn-white {
    background-color: white;
    color: #495057;
}

.btn.btn-white:hover {
    background-color: #cde3f2;
    color: #0077CB;
}
.btn.btn-border {
    border: 2px solid white;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
}
.btn-link {
    color: #0077CB;
}
/*************************************************** */
/* Tables */
/*************************************************** */
.table {

}
.table-caption {
    background-color: #cde3f2;
    color: #337ab7;
    padding-left: 40px;
    font-weight: 500;
    padding: 15px 0px;
}
.table td:first-of-type {
    color: #337ab7;
    padding-left: 40px;
    font-weight: 500;
}
.table td {
    color: #000;
    font-weight: 300;
    font-size:0.7rem;
    background-color: white;
    padding-top: 17px;
    padding-bottom: 17px;
}

table.pos-signature {
    border-spacing: 5px;
    border-collapse: separate;
    color: #b6b6b6;
}

table.pos-signature td {
    border-color: #b0b0b0 !important;

}
table.pos-signature td.table-success {
    background-color: #0077CB;
    color: white;
}
/*************************************************** */
/* Panels */
/*************************************************** */
.main-content-a {
    background-color: #f1f1f1;
}
.main-content-a .panel-a-white .table, .main-content-a .panel-a-white .container {
    padding-left: 0;
    background-color: #fff;
    padding-right: 0;
}
.main-content-a .panel-a-white .container {
    padding: 30px 25px;
}
.main-content-a .panel-a-white .table .table-caption,
.main-content-a .panel-a-white .table .table-body {
    padding-left: 50px;
    padding-right: 50px;

}

.main-content-a .panel-a-white .table .col {
    padding-left: 0px;
}
.main-content-a .panel-a-white .table .table-caption {
    text-transform: uppercase;
}

.main-content-a .panel-a-white .table .table-body {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 0.8rem;
    font-weight: 400;
}

.main-content-a .panel-a-white .table-body .field-divider {
    border-bottom: 1px solid #ddd;
}

.main-content-a .panel-a-white .table .field-row {
    padding-top: 12px;
    padding-bottom: 12px;
    text-transform: uppercase;
}
.main-content-a .panel-a-white .table .table-body br {
    display: none;
}

.main-content-a .panel-a-white .table .field-row .label {
    color: #0077cb;
    font-weight: 500;
}

.main-content-a .panel-a-white .MuiStepper-root {
    padding: 0;
}

.main-content-a .panel-a-white .MuiStepLabel-label {
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.45rem;
}
.main-content-a .panel-a-white .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-active,
.main-content-a .panel-a-white .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-completed
{
    color: #0077CB;
}
.main-content-a .panel-a-white .bs-stepper-circle {
    width: 1rem !important;
    height: 1rem !important;
    font-size: 0.6rem !important;
    line-height: 0.4rem !important;
}
.main-content-a .panel-a-white .bs-stepper-label {
    font-size: 0.7rem !important;
    text-transform: uppercase;
}


.main-content-a .panel-a-white h1 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
}
.main-content-a .panel-a-white form {
    margin-top: 30px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.main-content-a .panel-a-white form .form-row {
    font-size: 0.8rem;
    text-transform: none;
}

.main-content-a .panel-a-white form .form-row .invalid-feedback
, .main-content-a .panel-a-white form .form-row .invalid-feedback .text-muted {
    font-size: 0.6rem;
}
.main-content-a .panel-a-white form legend {
    font-size: 1rem;
}

.main-content-a .panel-a-white form .form-check {
    margin-bottom: 6px;
}

.main-content-a .panel-a-white form  .form-check-input {
    margin-top: 0.1em;
}

.main-content-a .panel-a-white form button {
    margin-top: 10px;
}


.main-content-a .panel-a-white form [type="text"]
, .main-content-a .panel-a-white form [type="number"]
, .main-content-a .panel-a-white form [type="tel"]
, .main-content-a .panel-a-white form [type="telephone"]
, .main-content-a .panel-a-white form [type="password"]
, .main-content-a .panel-a-white form [type="numeric"]
{
    /*margin-top: 6px !important; */
    border-radius: 0;
    font-size: 0.8rem;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus, .main-content-a .panel-a-white form button, .main-content-a .panel-a-white button:focus, .btn.focus, .btn:focus {
    box-shadow: none;

}

.main-content-a .panel-a-white form .form-row iframe {
    border: 1px solid #aaa;
    width: 100%;
}

/*************************************************** */
/* Footer */
/*************************************************** */

.fix-footer {

}
footer {
    margin-top: 30px;
}
.footer-content {
    background-color: #f1f1f1;
    border-top: 20px solid white;
    margin-top: 60px;


}
.footer-content .footer-columns {

    border-top: 20px solid #0077CB;

    padding-top: 30px;
    padding-bottom: 20px;

    padding-left: 4rem;
    padding-right: 4rem;

}

.footer-content .col-logo .logo {
    max-width:130px;
    margin-bottom: 10px;
}

.footer-content .col-logo .footer-text, .footer-content .col-logo .footer-copyright {

    color: #0077CB !important;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: none;
}

.footer-content .col-flag .flag {

    text-align: center;

}
.footer-content .col-flag .flag img {

    max-width:230px;
    margin: 0 auto;

}


.footer-content .col-socials .content-socials {
    text-align: right !important;

    margin-bottom: 10px;


}
.footer-content .col-socials .content-socials .social {

    margin-left: 20px;

}

.footer-content .col-socials .footer-menu {
    text-align: right;
    font-size: 0.8rem;
    font-weight:500;
}
.footer-content .col-socials .footer-menu a {
    margin-left: 20px;
    color: #0077CB;
}



/*************************************************** */
/* Tooltips */
/*************************************************** */

.alert {
    border-radius: 0;
}
.invalid-tooltip {
    border-radius: 0;
    font-size: 0.8rem;
}

/*************************************************** */
/* Pre-login */
/*************************************************** */
.cover-zone {
    background-image: url(https://vortica.net/img/cover.jpg);
    background-size: cover;
    background-color: black;
    background-position: center;
    height: 50vh;
    min-height: 300px;


    font-family: 'verdana', sans-serif;
    color: white;
    position: relative;
}

.cover-overlay {
    position: relative;

    background-image: linear-gradient(to right, rgba(0,0,0,0.4) , rgba(255,255,255,0));

    width: 100%;
    height: 100%;
}
.cover-text {
    text-transform: uppercase;
    margin: 50px;
    width: 40%;
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    position: absolute;
    bottom: 0;
    font-size: 3.0rem;
    font-weight: 600;
}


/*************************************************** */
/* Intro */
/*************************************************** */

.intro-area .intro-text {
    text-align: center;
    padding: 40px;
    text-transform: uppercase;

}

/* Only intro title */
.intro-area .intro-text .title {
    color: #0077CB;
    font-weight: 500;

}

.intro-area .intro-text .subtitle {
    color: #707070;
    font-size: 0.75rem;

}

.intro-area .intro-title .title {


    margin: 30px 0 15px 0;
    color: #0077CB;

    padding-left: 20px;
    border-left: 4px solid #d0d0d0;
    font-size: 1.5rem;
    font-weight: 500;

}

/* Intro Boxes */

.intro-boxes {
    margin-bottom: 40px;
}

.intro-boxes .intro-panel {
    display: flex;
    flex-direction: column;

    border: 10px solid transparent;
    background-clip:padding-box;
    background-color: white;
    padding: 20px 40px;
}
.intro-boxes .intro-panel .text-content {
    justify-content: normal;
}

.intro-boxes .intro-panel button {
    display: flex;
    align-self: flex-end;
}

.intro-boxes .intro-panel .title {
    color: #0077CB;
    font-weight: 500;
    text-transform: uppercase;
}
.intro-boxes .intro-panel p, .intro-boxes .intro-panel ul {
    color: #707070;
    font-size: 0.8rem;

}
.intro-boxes .intro-panel ul {
    list-style: none;
    padding: 10px;
}

.intro-boxes .intro-panel li {
    margin-bottom: 10px;
}

.intro-boxes .intro-panel ul li::before {
    content: "\2022";
    color: #0077CB;
    font-weight: bold;
    display: inline-block;
    width: 1.2em;
    margin-left: -1em;
}


.intro-boxes .intro-panel .intro-button button {

    text-decoration: none !important;
}
.intro-boxes .intro-panel .intro-button button a {
    color: white;
    text-decoration: none !important;
    position: relative;
    padding-right: 20px;
}
.intro-boxes .intro-panel .intro-button button a:after {
    content: '';
    position: absolute;
    left: 93%;
    top: 50%;
    padding-left: 20px;
    margin-top: -6px;
    border-left: 0;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 6px solid white;
}
.intro-boxes .intro-panel .full-button, .intro-boxes .intro-panel .intro-button  {
    margin-top: auto;
}

.intro-boxes .intro-panel .full-button {


    width: 100%;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-image: url('../img/bigarrow.png');


}
.intro-boxes .intro-panel .full-button a, .intro .intro-panel .full-button a:hover {
    color: white;
    text-decoration: none;

}
.intro-boxes .intro-panel .note {
    text-align: center;
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-top: 20px;
}

.form-group .col-md-5 {
    padding-left: 0;
}

/*************************************************** */
/* Mobile */
/*************************************************** */

@media only screen and (min-width: 992px) {

    /*************************************************** */
    /* BULLETS DESKTOP */
    /*************************************************** */
    .container-bullets {
        width:100%;
        /*margin-top: 0px;*/
    }
    .progressbar {
        counter-reset: step;
        width: 100%;
    }
    .progressbar li{
        list-style-type: none;
        float: left;
        width: 20%;
        position:relative;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        color: #0077cb;
        font-size: 0.7em;
    }
    .progressbar li:before {
        /* CSS for creating steper block before the li item*/
        content:counter(step);
        counter-increment: step;
        height:30px;
        width:30px;
        line-height: 30px;
        border: 1px solid #0077cb;
        display:block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;

        position: relative;
        z-index:99;
    }
    .progressbar li:after {
        /* CSS for creating horizontal line*/
        content:'';
        position: absolute;
        width:100%;
        height:1px;
        background-color: #ddd;
        top: 15px;
        left: -50%;
        z-index: 1;
    }
    .progressbar li:first-child:after {
        content:none;
    }

    .progressbar li.old-active {
        color:#0077cb;
    }
    .progressbar li.old-active {
        color:#0077cb;
    }
    .progressbar li.active {
        color:#0077cb;
        font-weight: 600;
    }
    .progressbar li.old-active:before {
        border-color:#0077cb;
        background-color: #cde3f2;
    }

    .progressbar li.active:before {
        border-color:#0077cb;
        border-width: 1px;
        background-color: #cde3f2;
    }
    .progressbar li.old-active + li:after{
        background-color:#0077cb;
    }
    .progressbar li.active + li:after{
        /* background-color:#0077cb; */
        /* background-color:red; */
    }
}

@media only screen and (max-width: 991px) {


    .cover-text {
        width: 50%;
    }

    .main-content-a {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    /*************************************************** */
    /* BULLETS MOBILE */
    /*************************************************** */

    .progressbar {
        display: none;
    }

}

@media only screen and (max-width: 767px) {
    .cover-text {

        font-size: 2.5em;
    }

    .footer-content .col-logo, .footer-content .col-flag, .footer-content .col-socials .content-socials, .footer-content .col-socials .footer-menu {
        text-align: center !important;
    }

    .footer-content .col-flag {

        margin: 30px 0;

    }
    .footer-content .col-socials .content-socials {

        margin-bottom: 20px;

    }

    .footer-content .col-socials .footer-menu {

        margin-bottom: 20px;

    }

    .btn {
        padding-left: 10px;
        padding-right: 10px;
    }
    .button-text {
        display: none;
    }

}

@media only screen and (max-width: 516px) {
    .cover-zone {
        height: 30vh;
    }

    .cover-text {
        font-size: 1.5em;
        width: 80%;
    }
    .cover-overlay {
        background-image: linear-gradient(15deg, rgba(0,0,0,0.5) 45% , rgba(0, 0, 0,0.0) 55% 100%);
        /* background-image: linear-gradient(15deg, rgba(255,0,0,0.5) 45% , rgba(0,0,255,0.0) 55% 100%); */

    }

    .main-content-a .MuiStepLabel-horizontal {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .navbar input {
        margin-top: 5px;
    }
    .navbar .navbar-brand img {
        max-width: 182px;
    }


}

@media only screen and (max-width: 575px) {


    [type="submit"], [type="button"] {

        font-size: 0.5rem;
        padding-left: 10px;
        padding-right: 10px;
    }


}

