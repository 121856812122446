/*container*/
.idp-page-container{
    background-color: white;
    margin-top: 30px;
}
/*buttons*/
.btn{
    padding:8px 16px!important;
    border-radius: var(--btn-border-radius);
  }
  /*btn back*/
  .btn-back{
    background: transparent!important;
    color: var(--brand-color)!important;
    border: 2px solid var(--brand-color)!important;
  }
  .btn-back:hover{
    color: var(--brand-color)!important;
    background-color: var(--hover-color)!important;
    border: 1px solid var(--hover-color)!important;
  }

  .idp-page-buttons .col{
   display: flex;   
   justify-content: center;
  }
  .idp-page-buttons .col:first-child{
    justify-content: flex-start;
   }
  .idp-page-buttons .col:last-child{
    justify-content: flex-end;
   }
  .idp-page-buttons .col .btn{
      width: auto!important;
      display: inline-block!important;
  }
  
/*navbar*/

.idp-nav form{
    position: absolute;
    right: 15px;
    bottom:-68px;
    z-index: 100;
}
.idp-nav form [type="submit"]{
    background: var(--brand-color);
    border-radius: 20px;
    font-weight: 600!important;
    color: white;
    align-self: auto;
    padding: .375rem .75rem!important;
    font-size: 12px!important;
    padding:8px 16px!important;
    border-radius: var(--btn-border-radius)!important;
    font-family: 'Montserrat', sans-serif!important;
}

.idp-nav form [type="submit"]:hover{
    color: var(--brand-color)!important;
    background-color: var(--hover-color)!important;
}

/*intro area*/
.intro-area .title{
    padding-right: 90px!important;
}


/*alerts*/
.alert{
    margin-top: 0!important;
    display: block;
    margin: 0 auto;
}
.alert p{
    margin: 0;
}


/*pre login*/


@media (min-width: 576px){
    .alert{
        max-width: 540px;
    }
}

@media (min-width: 768px){
    .alert {
        max-width: 720px;
    }
}

@media (min-width: 992px){
    .alert {
        max-width: 960px;
    }
}
@media (min-width: 1200px){
    .alert {
        max-width: 1140px;
    }
}

