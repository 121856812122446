
@import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../../../node_modules/@fontsource/poppins/300.css";
@import "../../../../../node_modules/@fontsource/poppins/400.css";
@import "../../../../../node_modules/@fontsource/poppins/500.css";
@import "../../../../../node_modules/@fontsource/poppins/600.css";
@import "../../../../../node_modules/@fontsource/poppins/700.css";
@import "../../../../../node_modules/@fontsource/poppins/800.css";
@import "../../../../../node_modules/@fontsource/source-sans-pro/600.css";
@import "../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css";


@import "./autentika.css";

.entityHeader{
    text-transform: initial;
}
.entityHeader b{
    text-transform: lowercase;
}
.custom-header-1{
    text-transform: uppercase;
    color: #0077CB;
}
.active .bs-stepper-circle{
    background-color: #0077CB ;
}
.bs-stepper-label {
    font-size: small;
}
/*
.container {
    max-width: 1440px;
}
*/
h1 {
    font-size: 24px;
    color: #0B79BB;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
}

body { text-transform: initial !important;}

.main-content-a .panel-a-white form .form-row {
    font-size: 0.8rem !important;
}


form input {
    border-radius: 0 !important;
    font-size: .8rem !important;
}